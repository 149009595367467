<template>
  <div class="">
    <SubHeader :data="data" />
    <div class="container">
      <div class="session-unlock">
        <h1 style="margin: 20px 0 30px 0px;">{{ $t(`Career['unLockYourPotential']`) }}</h1>
        <el-row :gutter="24" class="content-unlock">
          <el-col v-for="(item, index) in unlockPotetial" :key="index" :xs="24" :sm="12" :md="12" :lg="8" style="padding-bottom: 25px;">
            <div class="passion-cart">
              <h1>{{ $t(`Career['${item.title}']`) }}</h1>
              <p>{{ $t(`Career['${item.description}']`) }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="session-positions-available">
        <h1 style="text-align: center; margin: 20px 0px 50px;">{{ $t(`Career['availablePosition']`) }}</h1>
        <!-- <el-row :gutter="24">
          <el-col :span="8" style="text-align: left; margin-bottom: 20px;">
            <h1 style="font-size: 30px; font-weight: 500; margin-bottom: 0;">{{ $t(`Career['role']`) }}</h1>
            <el-select v-model="value" placeholder="All">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="text-align: left;">
            <h1 style="font-size: 30px; font-weight: 500; margin-bottom: 0;">{{ $t(`Career['department']`) }}</h1>
            <el-select v-model="value" placeholder="All">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8" style="text-align: left;">
            <h1 style="font-size: 30px; font-weight: 500; margin-bottom: 0;">{{ $t(`Career['employment']`) }}</h1>
            <el-select v-model="value" placeholder="All">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row> -->
        <el-row :gutter="24" class="content-unlock" style="margin-top: 20px;">
          <el-col v-for="(item, index) in positionAvailable" :key="index" :xs="24" :sm="12" :md="12" :lg="8">
            <router-link
              class="linkTo"
              :to="routes[index]"
            >
              <div @mouseover="isShowmore = index" @mouseleave="isShowmore = null" class="card-position">
                <h1>{{ $t(`Career['${item.title}']`) }}</h1>
                <div style="margin-bottom: 15px;">
                  <p style="font-style: italic;">{{ $t(`Career['${item.info}']`) }}</p>
                  <p style="color: #3370FF">{{ $t(`Career['deadLine']`) }}: {{ item.dateline }}</p>
                  <p>{{ $t(`Career['${item.duration}']`) }}</p>
                </div>
                <span>{{ $t(`Career['new']`) }}</span>
                <div class="btn-arrow"><el-button v-show="isShowmore === index" type="primary" icon="el-icon-right" circle></el-button></div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '../components/SubHeader.vue'

export default {
  components: {
    SubHeader
  },
  data() {
    return {
      routes: {
        0: '/careers/job-description/web-front-end',
        1: '/careers/job-description/FrontendArchitect',
        2: '/careers/job-description/JavaArchitect',
        3: '/careers/job-description/iOSEngineer',
        4: '/careers/job-description/AndroidEngineer',
        5: '/careers/job-description/UI_UXDesigner',
        6: '/careers/job-description/ProductManager'
      },
      data: {
        label_name: 'career_opportunity',
        content_desc: 'career_content',
        img: require('@/assets/image/sub-header/careers.png')
      },
      unlockPotetial: [
        {
          title: 'stableEmployment',
          description: 'weProvidesOppotunities'
        },
        {
          title: 'careerDevelopment',
          description: 'weAreConcentrateFocus'
        },
        {
          title: 'benefits',
          description: 'niceWorkPlace'
        },
        {
          title: 'collaborationTeam',
          description: 'weAreEngageSharing'
        },
        {
          title: 'encourageTeamwork',
          description: 'weThinkInnovative'
        },
        {
          title: 'learnTogether',
          description: 'weApproachingIntelligence'
        }
      ],
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      value: '',
      positionAvailable: [
        {
          title: 'title1',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        },
        {
          title: 'title2',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        },
        {
          title: 'title3',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        },
        {
          title: 'title4',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        },
        {
          title: 'title5',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        },
        {
          title: 'title6',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        },
        {
          title: 'title7',
          info: 'info',
          dateline: '31 Nov 2023',
          duration: 'duration'
        }
      ],
      isShowmore: true
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
.btn-arrow i.el-icon-right {
      font-weight: 700 !important;
  }
</style>

<style lang="less" scoped>
.session-unlock {
    padding: 30px 0 30px;
    h1 {
      font-size: 34px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
  }
}
.content-unlock.el-row {
  text-align: left;
  font-size: 16px;
  .passion-cart {
    background-color: #EBEBEB33;
    padding: 20px;
    border-radius: 20px;
    height: 20vh;
    h1 {
      font-size: 20px;
      text-align: left;
    }
    p {
      color: #595959;
    }
  }
  @media screen and (max-width: 1380px) {
    .passion-cart {
      height: 25vh;
    }
  }
}
.session-positions-available {
    padding: 30px 0 30px;
    h1 {
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 20px;
  }
  .el-select {
      width: 100%;
  }
  .card-position {
    position: relative;
    margin-bottom: 25px;
    padding: 35px;
    border: 1px solid #ddd;
    border-radius: 20px;
    height: 240px;
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 22px;
    }
    span {
      background-color: #FF4A4A;
      padding: 5px 15px;
      border-radius: 20px;
      color: #fff;
    }
    .btn-arrow {
      position: absolute;
      bottom: 15px;
      right: 15px;
      font-size: 20px;
    }
  }
  .card-position:hover {
      box-shadow: 0 0.5px 10px rgba(87, 87, 87, 0.2)}
  a.linkTo {
      color: #000;
  }
}
</style>
